import React from 'react'
import { Link } from 'react-router-dom'
import { capture } from '../../constant/Index'

function Newpassword() {
  return (
    <>

    {/* New Password Section */}
    <section className="login-sec">
        <div className="row">
          <div className="col-md-7">
            <div className="login-form-all">
              <form>
                <div className="row">
                  <div className="login-head forget-password-head">
                    <h2>Reset password</h2>
                    <p> Please enter a new password</p>
              
                  </div>
                  <div className="col-md-12">
                    <div className="form-dv-input">
                      <input type="password" placeholder="New Password" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-dv-input">
                      <input type="password" placeholder="Confirm New Password" />
                    </div>
                  </div>

                  {/* <div className="col-md-12">
                    <div className="remeber-and-forgot new">
                      <div class="form-group">
                        <input type="checkbox" id="html" />
                        <label for="html">Remeber me</label>
                      </div>

                      <div class="form-group">
                        <Link>Forgot Password ?</Link>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-md-12">
                    <div className="banner-button submit-banner-btn login-btn">
                      <Link to="/profile" className="all-button">
                      SUBMIT{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="col-md-5">
            <div className="login-form-img">
              <img className="img-fluid" src={capture} />
              <div className="login-form-text">
                <div className="about-banner-text login-all-text">
                  <h5>SMOKE PIPE EVERYDAY</h5>
                  <h2>PIPE PARADIES SMOKE SHOP.</h2>
                  <p>
                    Sed velit dignissim sodales ut eu sem. Phasellus egestas
                    tellus rutrum tellus pe lle ntesque eu tincidunt tortor
                    aliquam. Elit eget gravida cum sociis. Vitae turpis massa
                    sed elementum tempus egestas. Eu tincidunt tortor aliquam
                    nulla facilisi cras fermentum odio eu. Sed risus pretium
                    Condimentum lacinia quis vel eros donec ac. Sem et tortor
                    consequa
                  </p>

                  <p>
                    Sed velit dignissim sodales ut eu sem. Phasellus egestas
                    tellus rutrum tellus pe lle ntesque eu tincidunt tortor
                    aliquam. Elit eget gravida cum sociis. Vitae turpis{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
    </>
  )
}

export default Newpassword