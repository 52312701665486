import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageHeader from "../../components/Pageheader";
import { arrow, cartimg, checkoutimg } from "../../constant/Index";
import cart from "../../assets/css/cart.css";
import { Link } from "react-router-dom";
import Threelinks from "../../components/Threelinks";

function Cart() {
	const [counter, setCounter] = useState(0);
	const [counter1, setCounter1] = useState(1);
	const [counter2, setCounter2] = useState(1);
	const handleClick1 = () => {
		// Counter state is incremented
		setCounter(counter + 1);
	};
	const handleClick2 = () => {
		// Counter state is decremented
		setCounter(counter - 1);
	};

	const handleClick3 = () => {
		// Counter state is incremented
		setCounter1(counter1 + 1);
	};
	const handleClick4 = () => {
		// Counter state is decremented
		setCounter1(counter1 - 1);
	};

	const handleClick5 = () => {
		// Counter state is incremented
		setCounter2(counter2 + 1);
	};
	const handleClick6 = () => {
		// Counter state is decremented
		setCounter2(counter2 - 1);
	};
	return (
		<>
			<Header />
			<PageHeader pagename="YOUR CART" />

			{/* Cart section */}
			<section className="cart-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-8">
							<div className="Main-cart-table">
								<table className="cart-table">
									<thead>
										<th></th>
										<th>PRODUCT</th>
										<th>PRICE</th>
										<th>QUANTITY</th>
										<th>SUBTOTAL</th>
									</thead>

									<tbody className="t-body-all">
										<tr className="all-rows-tr">
											<td className="body-img">
												<img className="img-fluid" src={cartimg} />
												<div className="body-delete-all">
													<i class="fa fa-trash-o" aria-hidden="true"></i>
												</div>
											</td>

											<td className="for-back-color">Forum Cookie</td>
											<td className="for-back-color">$172.00</td>
											<td className="for-back-color">
												<div className="quantity-btn-all">
													<button onClick={handleClick2}>
														<i class="fa fa-minus" aria-hidden="true"></i>
													</button>
													<input value={counter} />
													<button onClick={handleClick1}>
														<i class="fa fa-plus" aria-hidden="true"></i>
													</button>
												</div>
											</td>
											<td className="for-back-color">$350.25</td>
										</tr>

										<tr className="all-rows-tr">
											<td className="body-img">
												<img className="img-fluid" src={cartimg} />
												<div className="body-delete-all">
													<i class="fa fa-trash-o" aria-hidden="true"></i>
												</div>
											</td>

											<td className="for-back-color">Forum Cookie</td>
											<td className="for-back-color">$172.00</td>
											<td className="for-back-color">
											<div className="quantity-btn-all">
													<button onClick={handleClick4}>
														<i class="fa fa-minus" aria-hidden="true"></i>
													</button>
													<input value={counter1} />
													<button onClick={handleClick3}>
														<i class="fa fa-plus" aria-hidden="true"></i>
													</button>
												</div>
											</td>
											<td className="for-back-color">$350.25</td>
										</tr>

										<tr className="all-rows-tr">
											<td className="body-img">
												<img className="img-fluid" src={cartimg} />
												<div className="body-delete-all">
													<i class="fa fa-trash-o" aria-hidden="true"></i>
												</div>
											</td>

											<td className="for-back-color">Forum Cookie</td>
											<td className="for-back-color">$172.00</td>
											<td className="for-back-color">
											<div className="quantity-btn-all">
													<button onClick={handleClick6}>
														<i class="fa fa-minus" aria-hidden="true"></i>
													</button>
													<input value={counter2} />
													<button onClick={handleClick5}>
														<i class="fa fa-plus" aria-hidden="true"></i>
													</button>
												</div>
											</td>
											<td className="for-back-color">$350.25</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div className="col-md-4">
							<div className="product-detail-all cart-detail-all ">
								<div className="product-head">
									<h5>ORDER SUMMARY</h5>
								</div>

								<div className="all-total cart-total-all">
									<div className="total">
										<p className="for-total-css">Total :</p>
										<p className="for-total-green">$152.00</p>
									</div>
									<div className="total">
										<p className="for-total-css">Shipping Charges : </p>
										<p className="for-total-green">$152.00</p>
									</div>
									<div className="total">
										<p className="for-total-css">Taxes Applied : </p>
										<p className="for-total-green">$52.00</p>
									</div>

									<div className="total order-hotel-all">
										<p className="for-total-css">Order Total : </p>
										<p className="for-total-green">$3025.00</p>
									</div>
								</div>

								<div className="row">
									<div className="col-md-6">
										<div className="banner-button login-btn cart-btn c-btn1">
											<Link className="all-button">
												UPDATE CART <img className="img-fluid" src={arrow} />{" "}
											</Link>
										</div>
									</div>

									<div className="col-md-6">
										<div className="banner-button login-btn cart-btn c-btn2">
											<Link to="/check-out" className="all-button">
												CHECKOUT <img className="img-fluid" src={arrow} />{" "}
											</Link>
										</div>
									</div>
								</div>
							</div>

							<div className="product-detail-all product-copun">
								<div className="product-head coupon-head">
									<h5>APPLY COUPON</h5>
									<p>If you have a coupon code, please apply it below.</p>
								</div>
								<div className="coupon-input">
									<input type="text" />
									<div className="coupon-dv">
										<div className="banner-button  cart-coupon-btn">
											<Link className="all-button">
												APPLY COUPON <img className="img-fluid" src={arrow} />{" "}
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
         <Threelinks />
			<Footer />
		</>
	);
}

export default Cart;
