import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { arrow } from "../../constant/Index";
import profile from "../../assets/css/profile.css";
import Sidebar from "./Sidebar";
import Threelinks from "../../components/Threelinks";

function Profile() {
  return (
    <>
      <Header />
      {/* My Profile Section */}
      <section className="my-profile-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9">
              <div className="profile-all">
                {/* Inner row */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="my-profile-head-and-btn">
                      <h3>My Profile</h3>
                      <div className="banner-button login-btn profile-edit-btn">
                        <Link to="/edit-profile" className="all-button">
                          EDIT PROFILE <img className="img-fluid" src={arrow} />{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="names-phones">
                      <h5>First Name</h5>
                      <p>James</p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="names-phones">
                      <h5>Last Name</h5>
                      <p>Anderson</p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="names-phones">
                      <h5>Phone</h5>
                      <p>+1 (322) 422 654</p>
                    </div>
                  </div>
                </div>

                <div className="row for-display-flex-using">
                  <div className="col-md-8">
                    <div className="names-phones">
                      <h5>Primary Email</h5>
                      <p>Jamesanderson@gmail.com</p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="names-phones only-for-text-center">
                      <h5>Password</h5>
                      <p>
                        ************{" "}
                        <span>
                          <Link to="/change-password">Change Password</Link>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="names-phones">
                      <h5>Address</h5>
                      <p>4806 Fittro Street hughes, AR 72348</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Threelinks />
      </section>
      <Footer />
    </>
  );
}

export default Profile;
