import React from "react";
import Header from "../../components/Header";
import { call, lounge1, lounge2, lounge3, plant } from "../../constant/Index";
import lounge from "../../assets/css/lounge.css";
import Footer from "../../components/Footer";
import Threelinks from "../../components/Threelinks";
import PageHeader from "../../components/Pageheader";

function Loun() {
  return (
    <>
      <Header />

      {/* <PageHeader pagename="orderzaza RENTAL" /> */}
      <div className="content-wrapper for-some-extra-css">
        <h5>
          SMOKE <span className="for-gap-in-words">PIPE </span>EVERYDAY
        </h5>
        <h2>
        PIPE PARADISE RENTAL
          <span>.</span>
        </h2>
     
      </div>

      {/* Lounge Section */}
      <section className="lounge-sec">
        <div className="container">
          <div className="lounge-header">
            <h5>$20 Per Hour (7 Days A Week ) From 11AM -8PM</h5>
            <p>After Hours - $40 Per Hour (7 Days A Week) From 8PM - 1 AM</p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="lounge-img ">
                <img className="img-fluid lounge-first-img" src={lounge1} />
                <div className="call-us-now">
                  <img className="img-fluid" src={call} />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="lounge-img">
                    <img className="img-fluid" src={lounge2} />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="lounge-img">
                    <img className="img-fluid" src={lounge3} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Threelinks />
      </section>
      {/* ================================== */}

      {/* Check and Para Section */}
      <section className="check-para-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="para-check">
                <p>
                  <span>
                    {" "}
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </span>
                  Experience the ultimate in relaxation and comfort at Pipe Paradise’s 420 smoke lounge.
                </p>
              </div>
              <div className="para-check">
                <p>
                  <span>
                    {" "}
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </span>
                  Escape the stress of daily life and unwind in our cozy and welcoming atmosphere.
                </p>
              </div>
              <div className="para-check">
                <p>
                  <span>
                    {" "}
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </span>
                  Take advantage of our wide selection of top-quality cannabis products.
                </p>
              </div>
              <div className="para-check">
                <p>
                  <span>
                    {" "}
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </span>
                  Enjoy our various amenities, including TVs, large speakers, and refreshments.
                </p>
              </div>
              <div className="para-check">
                <p>
                  <span>
                    {" "}
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </span>
                  Join in on occasional events and social gatherings at our lounge.
                </p>
              </div>
              <div className="para-check">
                <p>
                  <span>
                    {" "}
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </span>
                  Take advantage of our BYOB policy and make your smoke session even more enjoyable.
                </p>
              </div>
              <div className="para-check">
                <p>
                  <span>
                    {" "}
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </span>
                  We partner with the fantastic team at Order ZaZa to get your cannabis goods right to your ,doorstep. With Order ZaZa's speedy delivery, <br /> you won't have to wait long to enjoy your goodies.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="plant-img">
                <img className="img-fluid" src={plant} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Loun;
