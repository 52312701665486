import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { capture } from "../../constant/Index";

function Forgetpassword() {
  return (
    <>
      {/* Forgot Password */}
      <section className="login-sec">
        <div className="row">
          <div className="col-md-7">
            <div className="login-form-all">
              <form>
                <div className="row">
                  <div className="login-head forget-password-head">
                    <h2>Forget Password</h2>
                    <p>A password reset email will be sent</p>
                    <p className="login-para">
                      Haven't Recieved the link ?{" "}
                      <Link to="/register">Try Again</Link>
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div className="form-dv-input">
                      <input type="text" placeholder="Email" />
                    </div>
                  </div>

                  {/* <div className="col-md-12">
                    <div className="remeber-and-forgot new">
                      <div class="form-group">
                        <input type="checkbox" id="html" />
                        <label for="html">Remeber me</label>
                      </div>

                      <div class="form-group">
                        <Link>Forgot Password ?</Link>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-md-12">
                    <div className="banner-button submit-banner-btn login-btn">
                      <Link to="/new-password" className="all-button">
                        SUBMIT{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="col-md-5">
            <div className="login-form-img">
              <img className="img-fluid" src={capture} />
              <div className="login-form-text">
                <div className="about-banner-text login-all-text">
                  <h5>SMOKE PIPE EVERYDAY</h5>
                  <h2>PIPE PARADIES SMOKE SHOP.</h2>
                  {/* <p>
                    At orderzaza, we believe that cannabis can be a valuable part of a healthy and enjoyable lifestyle. That’s why we offer a wide range of top-quality cannabis products and accessories to suit all types of consumers. Whether you’re a seasoned pro or just starting out, we’ve got something for everyone. Our team is dedicated to providing excellent customer service and helping you find the perfect products to meet your needs. In addition to our retail offerings, we also have a 420 smoke lounge where you can relax and enjoy your purchases in a comfortable and welcoming atmosphere. We’re here to make your cannabis experience the best it can be. Thank you for choosing orderzaza.
                  </p> */}
                  <p>
                    At Pipe Paradise, we believe that cannabis can be a valuable
                    part of a healthy and enjoyable lifestyle. That’s why we
                    offer a wide range of top-quality cannabis products and
                    accessories to suit all types of consumers. Whether you’re a
                    seasoned pro or just starting out, we’ve got something for
                    everyone. Our team is dedicated to providing excellent
                    customer service and helping you find the perfect products
                    to meet your needs. In addition to our retail offerings, we
                    also have a 420 smoke lounge where you can relax and enjoy
                    your purchases in a comfortable and welcoming atmosphere.
                    We’re here to make your cannabis experience the best it can
                    be. Thank you for choosing Pipe Paradise.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==================================== */}
    </>
  );
}

export default Forgetpassword;
