import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";
import { arrow, blackflower, dollar } from "../../constant/Index";
import Sidebar from "./Sidebar";

function Loginmembership() {
  return (
    <>
      <Header />

      {/* Memebership Section */}
      <section className="member-sec my-profile-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9 profile-all">
              <div className="my-profile-head-and-btn">
                <h3>My MEMBERSHIP</h3>
              </div>
              <div className="row all-border-and-back">
                <div className="col-md-3 for-no-paddng">
                  <div className="all-become-member-div all-login-membership">
                    <div className="first-dv login-member-dv login-first-dv">
                      <div className="member-text login-member-text">
                        <h5>Basic</h5>
                        <h2>
                          <span className="dollar"><img className="img-fluid" src={dollar} /></span>350<span> /mo</span>
                        </h2>
                      </div>
                    </div>
                    <div className="become-member-ul">
                      <ul className="member-ul login-memeber-ul ">
                        <li className="border-set">
                          {" "}
                          <span>
                            <img className="img-fluid" src={blackflower} />
                          </span>{" "}
                          Member Card
                        </li>
                        <li className="border-set">
                          {" "}
                          <span>
                            <img className="img-fluid" src={blackflower} />
                          </span>{" "}
                          Earn Bonus
                        </li>
                        <li className="border-set">
                          {" "}
                          <span>
                            <img className="img-fluid" src={blackflower} />
                          </span>{" "}
                          Lounge Access
                        </li >
                        <li className="border-set">
                          {" "}
                          <span>
                            <img className="img-fluid" src={blackflower} />
                          </span>{" "}
                          Free Sample
                        </li>
                        <li >
                          {" "}
                          <span>
                            <img className="img-fluid" src={blackflower} />
                          </span>{" "}
                          Special Offers
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="my-profile-head-and-btn login-membership-second-col">
                    <h3>PLAN EXPIRES</h3>
                  </div>
                  <div className="login-member-col">
                    <h5>26-DECEMBER-2022</h5>
                    <h5>AT 11:35 GMT +5</h5>
                  </div>

                  <div className="login-memebr-btn-all">
                    <div className="banner-button member-btn-login login-btn">
                      <Link  className="all-button">
                      CANCEL MEMBERSHIP <img className="img-fluid" src={arrow} />{" "}
                      </Link>
                    </div>

                    <div className="banner-button member-btn-login login-btn for-no-margin">
                      <Link  className="all-button">
                      RENEW MEMBERSHIP <img className="img-fluid" src={arrow} />{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Threelinks />

      <Footer />
    </>
  );
}

export default Loginmembership;
