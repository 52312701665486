import React from "react";
import { Link } from "react-router-dom";
import { capture } from "../../constant/Index";

function Register() {
  return (
    <>
      <section className="login-sec">
        <div className="row">
          <div className="col-md-7">
            <div className="login-form-all register-form-all">
              <form>
                <div className="row">
                  <div className="login-head register-head">
                    <h2>Register</h2>
                    <p>
                      Welcome Back to <span>pipe paradise</span> <br />
                      The Best place to enhance your look{" "}
                    </p>
                    <p className="login-para">
                      Already a Member ? <Link to="/login">Login</Link>
                    </p>
                  </div>
                  <div className="col-md-6">
                    <div className="form-dv-input">
                      <input type="text" placeholder="First Name" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-dv-input">
                      <input type="text" placeholder="Last Name" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-dv-input">
                      <input type="email" placeholder="Email Address" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-dv-input">
                      <input type="text" placeholder="Phone Number" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-dv-input">
                      <input type="password" placeholder="Password" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-dv-input">
                      <input type="password" placeholder="Confirm Password" />
                    </div>
                  </div>

                  {/* <div className="col-md-12">
                    <div className="remeber-and-forgot new">
                      <div class="form-group">
                        <input type="checkbox" id="html" />
                        <label for="html">Remeber me</label>
                      </div>

                      <div class="form-group">
                        <Link>Forgot Password ?</Link>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-md-6">
                    <div className="banner-button submit-banner-btn login-btn">
                      <Link to="/login" className="all-button">
                        REGISTER{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="follow-us-on">
              <p>
                By Creating Account you are agree to our
                <span>
                  <Link> Terms & conditions</Link>
                </span>
              </p>
            </div>
          </div>

          <div className="col-md-5">
            <div className="login-form-img">
              <img className="img-fluid" src={capture} />
              <div className="login-form-text">
                <div className="about-banner-text login-all-text">
                  <h5>SMOKE PIPE EVERYDAY</h5>
                  <h2>PIPE PARADIES SMOKE SHOP.</h2>
                  <p>
                  Hello, and thank you for visiting Pipe Paradise, the web’s premier vendor of premium cannabis pipes, bowls, and accessories. We have several different options for both casual and devoted customers alike. When you work with us, you’ll be dealing with a team of people who care deeply about satisfying your needs and giving you the best possible customer service. In addition to our retail selection, we also have a 420 smoke lounge where you can kick back, relax, and enjoy your new purchases.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========================= */}
    </>
  );
}

export default Register;
