import React from "react";
import { Link } from "react-router-dom";
import { man, myorderview } from "../../constant/Index";
import { ChangeEvent, useRef, useState } from "react";
function Sidebar() {
	const [file, setFile] = useState();
	const inputRef = useRef(null);

	const handleUploadClick = () => {
		// 👇 We redirect the click event onto the hidden input element
		inputRef.current?.click();
	};

	const handleFileChange = (e) => {
		if (!e.target.files) {
			return;
		}

		setFile(e.target.files[0]);

		// 🚩 do the file upload here normally...
	};
	return (
		<>
			{/* Side bar section */}
			<div className="siderbar-tabs">
				<div className="sidebar-img">
					<img className="img-fluid" src={man} />
					{/* 👇 Notice the `display: hidden` on the input */}
					<input
						type="file"
						ref={inputRef}
						onChange={handleFileChange}
						style={{ display: "none" }}
					/>
					<div className="side-abr-icon" onClick={handleUploadClick}>
						<i class="fa fa-camera" aria-hidden="true"></i>
						{/* {file ? `${file.name}` : ""} */}
					</div>
				</div>
				<ul className="sidebar-ul">
					<li>
						<Link to="/profile">My Profile</Link>
					</li>
					<li>
						<Link to="/my-address">My Addresses</Link>
					</li>
					<li>
						<Link to="/my-orders">My Orders</Link>
					</li>
					<li>
						<Link to="/member-ship">My Membership</Link>
					</li>
					<li>
						<Link to="/login">Logout</Link>
					</li>
				</ul>
			</div>
			{/* ================ */}
		</>
	);
}

export default Sidebar;
