import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";
import { arrow, myorderview } from "../../constant/Index";
import Sidebar from "./Sidebar";

function Myorderviewdetail() {
  return (
    <>
      <Header />
      {/* My order view detail */}
      <section className="my-order-view-detail-sec my-profile-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9 profile-all">
              <div className="row">
                <div className="col-md-12">
                  <div className="my-profile-head-and-btn">
                    <h3>My orders</h3>
                    
                  </div>
                </div>
              </div>

              <div className="my-orders-view">
                <div className="row">
                  <div className="col-md-12">
                    <div className="order-views">
                      <h3>PRODUCT DETAILS</h3>
                      <div className="dv-for-flex">
                        <div className="two-stuff">
                          <div className="stuff-img">
                            <img className="img-fluid" src={myorderview} />
                          </div>
                          <div className="for-dv-text">
                            <h5>Forum Cookie</h5>
                            <p>(2x)</p>
                          </div>
                        </div>
                        <div className="for-price">
                          <h4>$539.00</h4>
                        </div>
                      </div>
                      <div className="dv-for-flex for-borderss">
                        <div className="two-stuff">
                          <div className="stuff-img">
                            <img className="img-fluid" src={myorderview} />
                          </div>
                          <div className="for-dv-text">
                            <h5>Forum Cookie</h5>
                            <p>(3x)</p>
                          </div>
                        </div>
                        <div className="for-price">
                          <h4>$539.00</h4>
                        </div>
                      </div>
                      <div className="dv-for-flex">
                        <div className="two-stuff">
                          <div className="stuff-img">
                            <img className="img-fluid" src={myorderview} />
                          </div>
                          <div className="for-dv-text">
                            <h5>Forum Cookie</h5>
                            <p>(4x)</p>
                          </div>
                        </div>
                        <div className="for-price">
                          <h4>$539.00</h4>
                        </div>
                      </div>
                    </div>

                    <div className="order-views order-views-bottom">
                      <h3>ORDER DETAILS</h3>
                      <div className="dv-for-flex">
                        <div className="two-stuff">
                          <div className="for-dv-text text-font-chnage">
                            <h5>Total :</h5>
                          </div>
                        </div>
                        <div className="for-price">
                          <h4>$152.00</h4>
                        </div>
                      </div>
                      <div className="dv-for-flex ">
                        <div className="two-stuff">
                          <div className="for-dv-text text-font-chnage">
                            <h5>Shipping Charges : </h5>
                          </div>
                        </div>
                        <div className="for-price">
                          <h4>$152.00</h4>
                        </div>
                      </div>
                      <div className="dv-for-flex">
                        <div className="two-stuff">
                          <div className="for-dv-text text-font-chnage">
                            <h5>Taxes Applied : </h5>
                          </div>
                        </div>
                        <div className="for-price">
                          <h4>$152.00</h4>
                        </div>
                      </div>

                      <div className="dv-for-flex">
                        <div className="two-stuff">
                          <div className="for-dv-text text-font-chnage">
                            <h5>Order Status : </h5>
                          </div>
                        </div>
                        <div className="for-price">
                          <h4>Completed</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Threelinks />
      </section>

      <Footer />
    </>
  );
}

export default Myorderviewdetail;
