import React from "react";
import events from "../../assets/css/events.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";
import { eventsinner } from "../../constant/Index";

function Eventsdetail() {
  return (
    <>
      <Header />
      {/* Event Detail Section */}
      <section className="Event-Detail-Section">
        <div className="container">
          <div className="about-banner-text ">
            <h5>
              SMOKE <span className="for-gap-in-words">PIPE</span> EVERYDAY
            </h5>
            {/* <h2>
              orderzaza Smoke Shop<span>.</span>
            </h2> */}
            <h2>
              ALL ABOUT PIPE PARADISE SMOKE SHOP <span>.</span>
            </h2>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="event-detail-all">
                <div className="events-img-all">
                  <img className="img-fluid" src={eventsinner} />
                  <div className="special-tag">
                    <p>Special offers</p>
                  </div>
                </div>

                <div className="event-para">
                  <p>
                    A seed was planted for Pipe Paradise in the heart of Detroit,
                    Michigan, in 2019, and by 2020, the business had expanded to
                    two sites. We have TVs, big speakers, refreshments, smoke
                    vents, and comfortable seats at our 420-friendly smoke
                    lounge. All of your requirements will be met.{" "}
                  </p>
                  <p>
                    Every day of the week, you may come to us for the finest
                    smoking experience possible because we’re open. Relax and
                    let go of your tension. Occasionally, we also host FREE
                    events and social gatherings in the lounge area. We are a
                    legal BYOB establishment. Everyone must be over the age of
                    21 to enter our lounge.{" "}
                  </p>
                  {/* <p>orderzaza is a premier retailer of high-quality cannabis products. We pride ourselves on offering a wide range of options to suit all types of consumers, from the occasional user to the seasoned connoisseur. </p> */}
                  <p>
                    Pipe Paradise is a premier retailer of high-quality cannabis
                    products. We pride ourselves on offering a wide range of
                    options to suit all types of consumers, from the occasional
                    user to the seasoned connoisseur.
                  </p>
                  <p>
                    Our operation is dedicated to providing excellent customer
                    service and ensuring that every visit to our store is a
                    pleasant and enjoyable experience. We take the time to
                    listen to our customers and help them find the perfect
                    products to meet their needs.{" "}
                  </p>
                  <p>
                    In addition to offering a wide selection of top-quality
                    cannabis products, we also strive to educate our customers
                    about responsible and safe use. We believe that cannabis can
                    be a beneficial and enjoyable part of a healthy lifestyle,
                    and we want to help our customers make informed decisions
                    about their use.
                  </p>
                  {/* <p>
                    At orderzaza, we are committed to being a responsible and
                    ethical business. We follow all laws and regulations related
                    to the sale of cannabis, and we are dedicated to making a
                    positive impact in our community.{" "}
                  </p> */}

                  <p>
                    At Pipe Paradise, we are committed to being a responsible
                    and ethical business. We follow all laws and regulations
                    related to the sale of cannabis, and we are dedicated to
                    making a positive impact in our community.
                  </p>
                  {/* <p>
                    You won’t find a more relaxed environment than what
                    orderzaza offers. Come on by; we’ve got the vibe and ease
                    that your cigarette is missing. Free membership nowadays is
                    severely lacking. We hope to see you at one of our locations
                    soon!{" "}
                  </p> */}
                  <p>Our Exclusive Partner –Order ZaZa </p>
                  <p>
                    At Pipe Paradise, we've partnered up with Order ZaZa to
                    bring our customers a top-notch delivery experience. And we
                    love the convenience and reliability that Order ZaZa offers.
                  </p>
                  <p>
                    Now, you might be wondering what Order ZaZa is all about.
                    Think of it like DoorDash, but only for all your cannabis
                    needs. Whether you're in the mood for a new piece or some
                    fresh flowers, Order ZaZa has got you covered. And when you
                    combine their lightning-fast delivery with Pipe Paradise's
                    premium products, it's a match made in heaven. Trust us, and
                    you'll be enjoying your goodies in no time.
                  </p>
                  <p>You won’t find a more relaxed environment than what pipe paradise offers. Come on by; we’ve got the vibe and ease that your cigarette is missing.</p>
                  <p>We hope to see you at one of our locations soon!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Threelinks />
      {/* ================================================== */}

      <Footer />
    </>
  );
}

export default Eventsdetail;
