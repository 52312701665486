import logo from './logo.svg';
import './App.css';
import style from "../src/assets/css/style.css";
import Publicroutes from './routes/Publicroutes';

function App() {
  return (
    <>
     <Publicroutes />
    </>
  );
}

export default App;
