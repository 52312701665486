import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import PageHeader from "../../components/Pageheader";
import { arrow, location1, location2, map } from "../../constant/Index";
import location from "../../assets/css/location.css";
import Footer from "../../components/Footer";
import Threelinks from "../../components/Threelinks";

function Location() {
  return (
    <>
      <Header />

      <PageHeader
        pagename="LOCATIONS"
         boldtext="Book Your Stay at Pipe Paradise's Tranquil Locations"
         pagepara ="Pipe paradise has two pristine locations all set in the mood. Get a subscription to our membership and lounge with the perfect roll. So join in for your next 420 sessions."
      />

      {/* Location Section */}
      <section className="location-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="location-img">
                <img className="img-fluid" src={location1} />

                <div className="location-text">
                  <div className="location-head">
                    <h3>
                      LODGE LOUNGE{" "}
                      <span>
                        <img className="img-fluid" src={map} />
                      </span>
                    </h3>
                    <p>
                      Sed velit dignissim sodales ut eu sem. Phasellus egestas
                      tellus rutrum tellus pe lle ntesque eu tincidunt tortor
                      aliquam. Elit eget{" "}
                    </p>
                  </div>
                  <label>FOR DIRECTIONS</label>
                  <div className="banner-button for-direct">
                    <Link className="all-button for-f-btn">
                      CLICK HERE
                      <img className="img-fluid" src={arrow} />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="location-img">
                <img className="img-fluid" src={location2} />

                <div className="location-text">
                  <div className="location-head">
                    <h3>
                      LODGE LOUNGE{" "}
                      <span>
                        <img className="img-fluid" src={map} />
                      </span>
                    </h3>
                    <p>
                      Sed velit dignissim sodales ut eu sem. Phasellus egestas
                      tellus rutrum tellus pe lle ntesque eu tincidunt tortor
                      aliquam. Elit eget{" "}
                    </p>
                  </div>
                  <label>FOR DIRECTIONS</label>
                  <div className="banner-button for-direct">
                    <Link className="all-button for-f-btn">
                      CLICK HERE
                      <img className="img-fluid" src={arrow} />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Threelinks />
      </section>
      <Footer />
    </>
   
  );
}

export default Location;
