import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";
import { arrow } from "../../constant/Index";
import Sidebar from "./Sidebar";

function Editprofile() {
  return (
    <>
      <Header />
      {/* Edit profile section */}
      <section className="edit-profile-sec my-profile-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9 profile-all">
              <div className="edit-profile-form">
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="my-profile-head-and-btn">
                        <h3>My Profile</h3>
                        <div className="banner-button login-btn profile-edit-btn">
                          <Link to="/profile" className="all-button">
                            SAVE PROFILE{" "}
                            <img className="img-fluid" src={arrow} />{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <label>First Name </label>
                        <input type="text" placeholder="Enter first name" />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <label>Last Name</label>
                        <input type="text" placeholder="Enter last name" />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <label>Phone Number</label>
                        <input type="text" placeholder="Enter phone number" />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="edit-profile-input">
                        <label>Primary Email</label>
                        <input type="email" placeholder="Enter email" />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="edit-profile-input">
                        <label>Address </label>
                        <input type="text" placeholder="Enter Address" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Threelinks />
      </section>
    </>
  );
}

export default Editprofile;
