import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageHeader from "../../components/Pageheader";
import Threelinks from "../../components/Threelinks";
import {
  arrow2,
  blackflower,
  shop1,
  shop2,
  shop3,
  shop4,
  shop5,
  shop6,
} from "../../constant/Index";

function Shop() {
  const shop = [
    {
      id: 1,
      img: shop1,
      heading: "FORUM COOKIE",
      para: "Lorem ipsum dolor sit amet psum dol",
      price: "USD 19.39",
      span: "THC : (25.8%)",
      g1: "7G",
      g2: "24G",
    },
    {
      id: 2,
      img: shop2,
      heading: "FORUM COOKIE",
      para: "Lorem ipsum dolor sit amet psum dol",
      price: "USD 19.39",
      span: "THC : (25.8%)",
      g1: "7G",
      g2: "24G",
    },
    {
      id: 3,
      img: shop3,
      heading: "FORUM COOKIE",
      para: "Lorem ipsum dolor sit amet psum dol",
      price: "USD 19.39",
      span: "THC : (25.8%)",
      g1: "7G",
      g2: "24G",
    },
    {
      id: 4,
      img: shop4,
      heading: "FORUM COOKIE",
      para: "Lorem ipsum dolor sit amet psum dol",
      price: "USD 19.39",
      span: "THC : (25.8%)",
      g1: "7G",
      g2: "24G",
    },
    {
      id: 5,
      img: shop5,
      heading: "FORUM COOKIE",
      para: "Lorem ipsum dolor sit amet psum dol",
      price: "USD 19.39",
      span: "THC : (25.8%)",
      g1: "7G",
      g2: "24G",
    },
    {
      id: 6,
      img: shop6,
      heading: "FORUM COOKIE",
      para: "Lorem ipsum dolor sit amet psum dol",
      price: "USD 19.39",
      span: "THC : (25.8%)",
      g1: "7G",
      g2: "24G",
    },
    {
      id: 7,
      img: shop4,
      heading: "FORUM COOKIE",
      para: "Lorem ipsum dolor sit amet psum dol",
      price: "USD 19.39",
      span: "THC : (25.8%)",
      g1: "7G",
      g2: "24G",
    },
    {
      id: 8,
      img: shop5,
      heading: "FORUM COOKIE",
      para: "Lorem ipsum dolor sit amet psum dol",
      price: "USD 19.39",
      span: "THC : (25.8%)",
      g1: "7G",
      g2: "24G",
    },
    {
      id: 9,
      img: shop4,
      heading: "FORUM COOKIE",
      para: "Lorem ipsum dolor sit amet psum dol",
      price: "USD 19.39",
      span: "THC : (25.8%)",
      g1: "7G",
      g2: "24G",
    },
  ];
  return (
    <>
      <Header />

      <PageHeader pagename="SHOP orderzaza" />
      {/* Smoke shop section */}
      <Threelinks />
      <section className="smoke-shop-section shop-inner-page-sec featured-products">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="shop-category">
                <ul className="shop-category-ul all-inner-shop-page">
                  <div className="shop-cate-div">
                    <h3>CATEGORIES</h3>
                  </div>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Sale Items
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Mushrooms
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      100 Ounces
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      What's New
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Preroll Blunt
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Vape Pen
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Preroll Blunt
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Indica Flowers
                    </Link>
                  </li>
                  <li className="for-link-flex">
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Hybrids Flowers{" "}
                    </Link>
                    <p>SAVE %25</p>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Concentrates
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-9">
              <div className="row">
                {shop.map((data, index) => {
                  return (
                    <div className="col-md-4">
                      <div className="all-shop-product-div shop-inner-dv">
                        <div className="shop-img">
                          <img className="img-fluid" src={data?.img}></img>
                        </div>
                        <h2>{data?.heading}</h2>
                        <p>{data?.para}</p>
                        <h5>
                          {data?.price}
                          <span className="small-data">{data?.span}</span>
                        </h5>
                        <div className="sevev-display">
                          <h4 className="given-margin">{data?.g1}</h4>
                          <h4>{data?.g2}</h4>
                        </div>

                        <div className="Link-all">
                          <Link to="/shop-detail">
                            ADD TO CART{" "}
                            <img className="img-fluid" src={arrow2} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
       
      </section>
      {/* ================================================== */}

      <Footer />
    </>
  );
}

export default Shop;
