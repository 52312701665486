import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { arrow } from "../../constant/Index";
import profile from "../../assets/css/profile.css";
import Sidebar from "./Sidebar";
import Threelinks from "../../components/Threelinks";

function Changepassword() {
  return (
    <>
      <Header />

      {/* Change Password */}
      <section className="change-password-sec my-profile-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9 profile-all">
              <div className="change-password-form">
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="my-profile-head-and-btn">
                        <h3>CHANGE PASSWORD</h3>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label>Current Password</label>
                      <div className="change-password-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label>New Password</label>
                      <div className="change-password-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label>Re-Enter New Password</label>
                      <div className="change-password-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="banner-button login-btn change-password-btn">
                            <Link className="all-button">
                              CANCEL <img className="img-fluid" src={arrow} />{" "}
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="banner-button login-btn change-password-btn">
                            <Link className="all-button">
                              SAVE PASSWORD{" "}
                              <img className="img-fluid" src={arrow} />{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Threelinks />
      </section>

      <Footer />
    </>
  );
}

export default Changepassword;
