import React from "react";
import { capture } from "../../constant/Index";
import login from "../../assets/css/login.css";
import { Link } from "react-router-dom";

function Login() {
  return (
    <>
      {/* Login Section */}

      <section className="login-sec">
        <div className="row">
          <div className="col-md-7">
            <div className="login-form-all">
              <form>
                <div className="row">
                  <div className="login-head">
                    <h2>LOGIN</h2>
                    <p>
                      Welcome Back to <span>pipe paradise</span>
                      <br />
                      The Best place to enhance your look{" "}
                    </p>
                    <p className="login-para">
                      Not a Member ? <Link to="/register">Register</Link>
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div className="form-dv-input">
                      <input type="text" placeholder="Email" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-dv-input">
                      <input type="email" placeholder="Password" />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="remeber-and-forgot new">
                      <div class="form-group">
                        <input type="checkbox" id="html" />
                        <label for="html"> remember me</label>
                      </div>

                      <div class="form-group">
                        <Link to="/forget-password">Forgot Password ?</Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="banner-button submit-banner-btn login-btn">
                      <Link to="/profile" className="all-button">
                        LOGIN{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="col-md-5">
            <div className="login-form-img">
              <img className="img-fluid" src={capture} />
              <div className="login-form-text">
                <div className="about-banner-text login-all-text">
                  <h5>SMOKE PIPE EVERYDAY</h5>
                  <h2>PIPE PARADISE SMOKE SHOP</h2>
                  <p>
                    Welcome to Pipe Paradise, your go-to source for high-quality
                    cannabis products and accessories. We offer a wide range of
                    options to suit all types of consumers, from the occasional
                    user to the seasoned connoisseur.
                  </p>
                  <p>
                    Our friendly and knowledgeable staff are dedicated to
                    providing excellent customer service and helping you find
                    the perfect products to meet your needs.{" "}
                  </p>
                  <p>In addition to our retail offerings, we also have a 420 smoke lounge where you can relax and enjoy your purchases in a comfortable and welcoming atmosphere.</p>
                  <p>Note : We use Order Zaza to deliver your cannabis goods. With Pipe Paradise's top-quality products and Order ZaZa's speedy delivery, you'll be enjoying your goodies in no time.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ===================== */}
    </>
  );
}

export default Login;
