import React from "react";
import PageHeader from "../../components/Pageheader";
import events from "../../assets/css/events.css";
import {
  event1,
  event2,
  event3,
  greenclock,
  special1,
  special2,
  special3,
  special4,
  whiteclock,
} from "../../constant/Index";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";

function Events() {
  const eventer = [
    {
      id: 1,
      img: special1,
      img1: greenclock,
      heading: "orderzaza Smoke Shop",
      para: "Sed velit dignissim sodales ut eu sem. Phasellus egestas tellus rutrum tellus pe lle ntesque eu tincidunt tortor aliquam. Elit eget gravida cum sociis",
      date: " 18 MAY 2022",
      specialtag:"Special offers",
    },
    {
      id: 2,
      img: special2,
      img1: greenclock,
      heading: "orderzaza Smoke Shop",
      para: "Sed velit dignissim sodales ut eu sem. Phasellus egestas tellus rutrum tellus pe lle ntesque eu tincidunt tortor aliquam. Elit eget gravida cum sociis",
      date: " 18 MAY 2022",
      specialtag:"Special offers",
    },
    {
      id: 3,
      img: special3,
      img1: greenclock,
      heading: "orderzaza Smoke Shop",
      para: "Sed velit dignissim sodales ut eu sem. Phasellus egestas tellus rutrum tellus pe lle ntesque eu tincidunt tortor aliquam. Elit eget gravida cum sociis",
      date: " 18 MAY 2022",
      specialtag:"Special offers",
    },
    {
      id: 4,
      img: special4,
      img1: greenclock,
      heading: "orderzaza Smoke Shop",
      para: "Sed velit dignissim sodales ut eu sem. Phasellus egestas tellus rutrum tellus pe lle ntesque eu tincidunt tortor aliquam. Elit eget gravida cum sociis",
      date: " 18 MAY 2022",
      specialtag:"Special offers",
    },
    {
      id: 5,
      img: special1,
      img1: greenclock,
      heading: "orderzaza Smoke Shop",
      para: "Sed velit dignissim sodales ut eu sem. Phasellus egestas tellus rutrum tellus pe lle ntesque eu tincidunt tortor aliquam. Elit eget gravida cum sociis",
      date: " 18 MAY 2022",
      specialtag:"Special offers",
    },
    {
      id: 6,
      img: special2,
      img1: greenclock,
      heading: "orderzaza Smoke Shop",
      para: "Sed velit dignissim sodales ut eu sem. Phasellus egestas tellus rutrum tellus pe lle ntesque eu tincidunt tortor aliquam. Elit eget gravida cum sociis",
      date: " 18 MAY 2022",
      specialtag:"Special offers",
    },
    {
      id: 7,
      img: special4,
      img1: greenclock,
      heading: "orderzaza Smoke Shop",
      para: "Sed velit dignissim sodales ut eu sem. Phasellus egestas tellus rutrum tellus pe lle ntesque eu tincidunt tortor aliquam. Elit eget gravida cum sociis",
      date: " 18 MAY 2022",
      specialtag:"Special offers",
    },
    {
      id: 8,
      img: special3,
      img1: greenclock,
      heading: "orderzaza Smoke Shop",
      para: "Sed velit dignissim sodales ut eu sem. Phasellus egestas tellus rutrum tellus pe lle ntesque eu tincidunt tortor aliquam. Elit eget gravida cum sociis",
      date: " 18 MAY 2022",
      specialtag:"Special offers",
    },
  ];
  return (
    <>
    <Header />
      <PageHeader
        pagename="EVENTS & SPECIALS"
        boldtext="Join the Fun: Discover Pipe Paradise's Exciting Events and Unbeatable Specials"
        pagepara= "From live music to cannabis tastings, our events offer the perfect opportunity to elevate your 420 experience. Plus, stay in the loop on our latest specials and discounts to save big on your next visit!"
      />

      {/* Event-section */}
      <section className="event-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="event-img">
                <img className="img-fluid for-adjust" src={event1} />
                <div className="event-text">
                  <h3>orderzaza Smoke Shop</h3>
                  <h5>
                    <span className="event-clock-img">
                      <img className="img-fluid" src={whiteclock} />
                    </span>
                    18 MAY 2022
                  </h5>
                  <p>
                    Sed velit dignissim sodales ut eu sem. Phasellus egestas
                    tellus rutrum tellus pe lle ntesque eu tincidunt tortor
                    aliquam. Elit eget gravida cum sociis. Vitae turpis massa
                    sed elementum tempus egestas. Eu tincidunt tortor aliquam
                    nulla facilisi cras fermentum odio eu. Sed risus{" "}
                  </p>
                  <Link>READ MORE</Link>
                </div>
                <div className="special-tag">
                  <p>Special offers</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 for-background-color">
              <div className="row for-align-setting">
                <div className="col-md-7">
                  <div className="members-t">
                    <h3>orderzaza Smoke Shop</h3>
                    <h5>
                      <span className="member-clock-img">
                        <img className="img-fluid" src={greenclock} />
                      </span>
                      18 MAY 2022
                    </h5>
                    <p>
                      Sed velit dignissim sodales ut eu sem. Phasellus egestas
                      tellus rutrum tellus pe lle ntesque eu tincidunt tortor
                      aliquam. Elit eget gravida cum sociis. Vitae turpis massa
                      sed elementum tempus egestas. Eu tincidunt tortor aliquam
                      nulla facilisi cras fermentum odio eu. Sed risus{" "}
                    </p>
                    <Link>READ MORE</Link>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="member-t-img">
                    <img className="img-flui" src={event2} />
                    <div className="member-t-tag">
                      <p>members</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row for-align-setting">
                <div className="col-md-7">
                  <div className="members-t">
                    <h3>orderzaza Smoke Shop</h3>
                    <h5>
                      <span className="member-clock-img">
                        <img className="img-fluid" src={greenclock} />
                      </span>
                      18 MAY 2022
                    </h5>
                    <p>
                      Sed velit dignissim sodales ut eu sem. Phasellus egestas
                      tellus rutrum tellus pe lle ntesque eu tincidunt tortor
                      aliquam. Elit eget gravida cum sociis. Vitae turpis massa
                      sed elementum tempus egestas. Eu tincidunt tortor aliquam
                      nulla facilisi cras fermentum odio eu. Sed risus{" "}
                    </p>
                    <Link>READ MORE</Link>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="member-t-img">
                    <img className="img-flui" src={event3} />
                    <div className="member-t-tag">
                      <p>members</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Threelinks />
      </section>
      {/* ================================= */}

      {/* Event Special Section */}
      <section className="event-special-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                {eventer.map((data, index) => {
                  return (
                    <div className="col-md-3">
                      <div className="event-special-all">
                        <div className="event-img">
                          <img className="img-fluid" src={data?.img} />
                          <div className="mem-t">
                            <h3>{data?.heading}</h3>
                            <h5>
                              <span className="member-clock-img">
                                <img className="img-fluid" src={data?.img1} />
                              </span>
                              {data?.date}
                            </h5>
                            <p>{data?.para}</p>
                            <Link to="/events-detail">READ MORE</Link>
                          </div>
                        </div>
                        <div className="special-event-tag">
                          <p>{data?.specialtag}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Events;
