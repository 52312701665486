import React from "react";
import { Link } from "react-router-dom";
import {
  about1,
  about2,
  about3,
  abouticon,
  arrow,
  hand,
  homeabout,
} from "../../constant/Index";
import about from "../../assets/css/about.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Threelinks from "../../components/Threelinks";

function About() {
  return (
    <>
      <Header />
      {/* About section */}
      <section className="banner-about-sec about-ban-sec about-sec-first-ban inner-about-para-color">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about-img">
                <div className="about-icon-img">
                  <div className="img-1">
                    <img className="img-fluid" src={hand} />
                    <h5>SMOKE LOUNGE</h5>
                  </div>
                </div>
                <img className="img-fluid" src={homeabout} />
                <div className="about-icon-img2">
                  <div className="img-2">
                    <img className="img-fluid" src={abouticon} />
                    <h5>CANABIS SMOKING</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-banner-text">
                <h5>PRIVATE <span className="for-gap-in-words">MEMBERS</span> LOUNGE</h5>
                <h2>
                  ABOUT orderzaza<span>.</span>
                </h2>
                <p>
                  ur #420 friendly smoke lounge is a fun vibes, comfortable
                  space with TV’s, large speaker, complimentary refreshments,
                  smoke ventilation and comfortable seating. Available 7 days a
                  week for unwinding and relaxation. We also offer FREE events
                  or rental of the lounge area for private or social gatherings.
                  BYOB permitted, must be 21+ to enter. For more information
                  visit our frequently asked questions section, stop in and see
                  us, or contact us anytime.
                </p>

                <div className="banner-button btn-for-about-ban">
                  <Link to="/shop" className="all-button">
                    SHOP NOW
                    <img className="img-fluid" src={arrow} />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Threelinks />
      </section>
      {/* ================================================================= */}

      {/* About-section-second  */}
      <section className="banner-about-sec about-second-sec inner-about-para-color">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about-banner-text">
                <h5>PRIVATE <span className="for-gap-in-words">MEMBERS</span> LOUNGE</h5>
                <h2>
                  ABOUT orderzaza<span>.</span>
                </h2>
                <p>
                  ur #420 friendly smoke lounge is a fun vibes, comfortable
                  space with TV’s, large speaker, complimentary refreshments,
                  smoke ventilation and comfortable seating. Available 7 days a
                  week for unwinding and relaxation. We also offer FREE events
                  or rental of the lounge area for private or social gatherings.
                  BYOB permitted, must be 21+ to enter. For more information
                  visit our frequently asked questions section, stop in and see
                  us, or contact us anytime.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam.
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="whole-second-box">
                <div className="about-img about-second-img">
                  <img className="img-fluid" src={about1} />
                </div>

                <div className="about-small-box">
                  <h5>Cobus Bester <span>- Nov 29 ,2022</span></h5>
                  <p>
                    Sed velit dignissim sodales ut eu sem. Phasellus egestas
                    tellus rutrum tellus
                  </p>
                  <div className="stars reviews-stars">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                  </div>
                  <div className="">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ================================================================= */}

      {/* Spining section */}
      <section className="spin-sec spin-second-sec ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="spin-text">
                <div className="about-banner-text shop-banner-text spin-div spin-second-div">
                  <h5>SMOKE <span className="for-gap-in-words">PIPE</span> EVERYDAY</h5>
                  <h2>DO YOU HAVE</h2>
                  <h2>
                    MORE QUESTIONS<span>?</span>
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco lLorem ipsum dolor sit amet, consectetur usmod.
                  </p>
                  <div className="banner-button spin-btn about-contact-btn">
                    <Link to="/contact-us" className="all-button">
                      CONTACT US<img className="img-fluid" src={arrow} />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========================================================== */}

      {/* About-section-Third  */}
      <section className="banner-about-sec  about-third-sec inner-about-para-color">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about-img about-third-img">
                <img className="img-fluid" src={about3} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-banner-text">
                <h5>PRIVATE <span className="for-gap-in-words">MEMBERS</span> LOUNGE</h5>
                <h2>
                  ABOUT orderzaza<span>.</span>
                </h2>
                <p>
                  ur #420 friendly smoke lounge is a fun vibes, comfortable
                  space with TV’s, large speaker, complimentary refreshments,
                  smoke ventilation and comfortable seating. Available 7 days a
                  week for unwinding and relaxation. We also offer FREE events
                  or rental of the lounge area for private or social gatherings.
                  BYOB permitted, must be 21+ to enter. For more information
                  visit our frequently asked questions section, stop in and see
                  us, or contact us anytime.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ================================================================= */}

      <Footer />
    </>
  );
}

export default About;
