import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Slide from "../../components/Slide";
import shop from "../../assets/css/shop.css";
import {
	arrow,
	arrow2,
	review1,
	review2,
	review3,
	shop1,
	shop2,
	shop3,
	shop4,
	shop5,
	shop6,
} from "../../constant/Index";
import { Link } from "react-router-dom";
import Threelinks from "../../components/Threelinks";

function Shopdetail() {
	const [counter, setCounter] = useState(0);
	const handleClick1 = () => {
		// Counter state is incremented
		setCounter(counter + 1);
	};
	const handleClick2 = () => {
		// Counter state is decremented
		setCounter(counter - 1);
	};

	const shop = [
		{
			id: 1,
			img: shop1,
			heading: "FORUM COOKIE",
			para: "Lorem ipsum dolor sit amet psum dol",
			price: "USD 19.39",
			span: "THC : (25.8%)",
			g1: "7G",
			g2: "24G",
			toppro: "Top Product",
		},
		{
			id: 2,
			img: shop2,
			heading: "FORUM COOKIE",
			para: "Lorem ipsum dolor sit amet psum dol",
			price: "USD 19.39",
			span: "THC : (25.8%)",
			g1: "7G",
			g2: "24G",
		},
		{
			id: 3,
			img: shop3,
			heading: "FORUM COOKIE",
			para: "Lorem ipsum dolor sit amet psum dol",
			price: "USD 19.39",
			span: "THC : (25.8%)",
			g1: "7G",
			g2: "24G",
			topsale: "sale",
		},
		{
			id: 4,
			img: shop4,
			heading: "FORUM COOKIE",
			para: "Lorem ipsum dolor sit amet psum dol",
			price: "USD 19.39",
			span: "THC : (25.8%)",
			g1: "7G",
			g2: "24G",
		},
	];
	var value = 1;
	return (
		<>
			<Header />
			{/* Shop detail section     */}
			<section className="shop-detail-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<Slide />
						</div>

						<div className="col-md-6">
							<div className="all-shop-cart">
								<div className="shop-head">
									<h5>IN STOCK</h5>
									<h2>
										FORUM COOKIE<span>.</span>
									</h2>
								</div>

								<div className="shop-reviews-all">
									<div className="sop-cutomer-reivies">
										<div className="stars">
											<i class="fa fa-star" aria-hidden="true"></i>
											<i class="fa fa-star" aria-hidden="true"></i>
											<i class="fa fa-star" aria-hidden="true"></i>
											<i class="fa fa-star" aria-hidden="true"></i>
											<i class="fa fa-star" aria-hidden="true"></i>
										</div>

										<div className="customer">
											<p>(4 customer reviews)</p>
										</div>
									</div>

									<div className="quick-reiview">
										<h3>Quick Overview</h3>

										<div className="sevev-dis">
											<h4 className="given-margin">7g</h4>
											<h4>24g</h4>
										</div>
									</div>

									<div className="para-customer-review">
										<p>
											Sed velit dignissim sodales ut eu sem. Phasellus egestas
											tellus rutrum tellus pe lle ntesque eu tincidunt tortor
											aliquam. Elit eget gravida cum sociis. Vitae turpis massa
											sed elementum tempus egestas. Eu tincidunt tortor aliquam
											nulla facilisi cras fermentum odio eu. Sed risus pretium{" "}
										</p>
									</div>

									<div className="para-us">
										<h5>
											USD 19.39 <span>THC : (25.8%)</span>
										</h5>
									</div>

									<div className="quainty-and-button">
										<div className="quantity">
											<button onClick={handleClick2}>
												<i class="fa fa-minus" aria-hidden="true"></i>
											</button>
											<input value={counter} />
											<button onClick={handleClick1}>
												<i class="fa fa-plus" aria-hidden="true"></i>
											</button>
										</div>
										<div className="banner-button Add-to-cart-on-detail-page">
											<Link to="/cart" className="all-button">
												ADD TO CART{" "}
												<img className="img-fluid" src={arrow} />{" "}
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Threelinks />
			</section>
			{/* ================================================ */}

			{/* Tabs section */}
			<section className="reviews-tab-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="tabs-alls">
								<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
									<li class="nav-item" role="presentation">
										<button
											class="nav-link active"
											id="pills-home-tab"
											data-bs-toggle="pill"
											data-bs-target="#pills-home"
											type="button"
											role="tab"
											aria-controls="pills-home"
											aria-selected="true"
										>
											DESCRIPTION
										</button>
									</li>
									<li class="nav-item" role="presentation">
										<button
											class="nav-link"
											id="pills-profile-tab"
											data-bs-toggle="pill"
											data-bs-target="#pills-profile"
											type="button"
											role="tab"
											aria-controls="pills-profile"
											aria-selected="false"
										>
											REVIEWS <span className="for-number">(4)</span>
										</button>
									</li>
								</ul>
								<div class="tab-content" id="pills-tabContent">
									<div
										class="tab-pane fade show active"
										id="pills-home"
										role="tabpanel"
										aria-labelledby="pills-home-tab"
									>
										<div className="description-para">
											<p>
												Sed velit dignissim sodales ut eu sem. Phasellus egestas
												tellus rutrum tellus pellentesque eu tincidunt tortor
												aliquam. Elit eget gravida cum sociis. Vitae turpis
												massa sed elementum tempus egestas. Eu tincidunt tortor
												aliquam nulla facilisi cras fermentum odio eu. Sed risus
												pretium Sed velit dignissim sodales ut eu sem. Phasellus
												egestas tellus rutrum tellus pellentesque eu tincidunt
												tortor aliquam. Elit eget gravida cum sociis. Vitae
												turpis massa sed elementum tempus egestas. Eu tincidunt
												tortor aliquam nulla facilisi cras fermentum odio eu.
												Sed risus pretium
											</p>

											<p>
												Condimentum lacinia quis vel eros donec ac. Sem et
												tortor consequat id porta nibh venenatis. Turpis egestas
												maecenas pharetra
											</p>

											<p>
												Condimentum lacinia quis vel eros donec ac. Sem et
												tortor consequat id porta nibh venenatis. Turpis egestas
												maecenas pharetraSed velit dignissim sodales ut eu sem.
												Phasellus egestas tellus rutrum tellus pellentesque eu
												tincidunt tortor aliquam. Elit eget gravida cum sociis.
												Vitae turpis massa sed elementum tempus egestas. Eu
												tincidunt tortor aliquam nulla facilisi cras fermentum
												odio eu. Sed risus pretium
											</p>

											<p>
												Condimentum lacinia quis vel eros donec ac. Sem et
												tortor consequat id porta nibh venenatis. Turpis egestas
												maecenas pharetra
											</p>
										</div>
									</div>
									<div
										class="tab-pane fade"
										id="pills-profile"
										role="tabpanel"
										aria-labelledby="pills-profile-tab"
									>
										<div className="reviews-all">
											<div className="review-para">
												<p>4 reviews for Our Products</p>
											</div>
											{/* Reviews-Row   */}
											<div className="row for-margin">
												<div className="col-md-1">
													<div className="review-img">
														<img className="img-fluid" src={review2} />
													</div>
												</div>
												<div className="col-md-11">
													<div className="review-tab-all">
														<div className="tab-text">
															<h5>
																Cobus Bester <span>- Nov 29 ,2022</span>
															</h5>
															<p>
																Sed velit dignissim sodales ut eu sem. Phasellus
																egestas tellus rutrum tellus pe lle ntesque eu
																tincidunt{" "}
															</p>
														</div>
														<div className="stars reviews-stars">
															<i class="fa fa-star" aria-hidden="true"></i>
															<i class="fa fa-star" aria-hidden="true"></i>
															<i class="fa fa-star" aria-hidden="true"></i>
															<i class="fa fa-star" aria-hidden="true"></i>
															<i class="fa fa-star" aria-hidden="true"></i>
														</div>
													</div>
												</div>
											</div>
											{/* =========================== */}
											{/* Reviews-Row   */}
											<div className="row for-margin">
												<div className="col-md-1">
													<div className="review-img">
														<img className="img-fluid" src={review1} />
													</div>
												</div>
												<div className="col-md-11">
													<div className="review-tab-all">
														<div className="tab-text">
															<h5>
																Cobus Bester <span>- Nov 29 ,2022</span>
															</h5>
															<p>
																Sed velit dignissim sodales ut eu sem. Phasellus
																egestas tellus rutrum tellus pe lle ntesque eu
																tincidunt{" "}
															</p>
														</div>
														<div className="stars reviews-stars">
															<i class="fa fa-star" aria-hidden="true"></i>
															<i class="fa fa-star" aria-hidden="true"></i>
															<i class="fa fa-star" aria-hidden="true"></i>
															<i class="fa fa-star" aria-hidden="true"></i>
															<i class="fa fa-star" aria-hidden="true"></i>
														</div>
													</div>
												</div>
											</div>
											{/* =========================== */}
											{/* Reviews-Row   */}
											<div className="row for-margin">
												<div className="col-md-1">
													<div className="review-img">
														<img className="img-fluid" src={review3} />
													</div>
												</div>
												<div className="col-md-11">
													<div className="review-tab-all">
														<div className="tab-text">
															<h5>
																Cobus Bester <span>- Nov 29 ,2022</span>
															</h5>
															<p>
																Sed velit dignissim sodales ut eu sem. Phasellus
																egestas tellus rutrum tellus pe lle ntesque eu
																tincidunt{" "}
															</p>
														</div>
														<div className="stars reviews-stars">
															<i class="fa fa-star" aria-hidden="true"></i>
															<i class="fa fa-star" aria-hidden="true"></i>
															<i class="fa fa-star" aria-hidden="true"></i>
															<i class="fa fa-star" aria-hidden="true"></i>
															<i class="fa fa-star" aria-hidden="true"></i>
														</div>
													</div>
												</div>
											</div>
											{/* =========================== */}

											<div className="review-form">
												<div className="add-text">
													<h5>Add a review</h5>
													<p>
														Your email address will not be published.Required
														field are marked <span>*</span>
													</p>
													<p>Your rating</p>
													<div className="add-reviews-stars">
														<i class="fa fa-star-o" aria-hidden="true"></i>
														<i class="fa fa-star-o" aria-hidden="true"></i>
														<i class="fa fa-star-o" aria-hidden="true"></i>
														<i class="fa fa-star-o" aria-hidden="true"></i>
														<i class="fa fa-star-o" aria-hidden="true"></i>
													</div>
												</div>
											</div>

											<form>
												<div className="row">
													<div className="col-md-4">
														<div className="input-reviews">
															<label>Name</label>
															<input
																type="text"
																placeholder="Write your name here.."
															/>
														</div>
													</div>

													<div className="col-md-4">
														<div className="input-reviews">
															<label>Email Address</label>
															<input
																type="text"
																placeholder="Write your email here..."
															/>
														</div>
													</div>
													<div className="col-md-4">
														<div className="input-reviews">
															<label>Phone Number</label>
															<input
																type="text"
																placeholder="Write your phone here..."
															/>
														</div>
													</div>

													<div className="col-md-12">
														<div className="form-text-area">
															<label>Your Review</label>
															<textarea
																class="form-control"
																id="exampleFormControlTextarea1"
																rows="3"
																placeholder="Write your message here..."
															></textarea>
														</div>
													</div>

													<div className="col-md-6 hide-on-responsive"></div>
													<div className="col-md-6">
														<div className="banner-button add-review-btn">
															<Link className="all-button">
																S U B M I T{" "}
																<img className="img-fluid" src={arrow} />{" "}
															</Link>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* =========================================================== */}

			{/* Smoke shop section */}
			<section className="smoke-shop-section featured-products">
				<div className="container">
					<div className="about-banner-text shop-banner-text">
						<h5>
							SMOKE <span className="for-gap-in-words">PIPE</span> EVERYDAY
						</h5>
						<h2>
							FEATURED PRODUCTS<span>.</span>
						</h2>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="row">
								{shop.map((data, index) => {
									return (
										<div className="col-md-3">
											<div className="all-shop-product-div">
												<div className="shop-img">
													<img className="img-fluid" src={data?.img}></img>
												</div>
												<h2>{data?.heading}</h2>
												<p>{data?.para}</p>
												<h5>
													{data?.price}
													<span className="small-data">{data?.span}</span>
												</h5>
												<div className="sevev-display">
													<h4 className="given-margin">{data?.g1}</h4>
													<h4>{data?.g2}</h4>
												</div>

												<div className="Link-all">
													<Link to="/shop-detail">
														ADD TO CART{" "}
														<img className="img-fluid" src={arrow2} />
													</Link>
												</div>

												<div className="t-product">
													<p>{data?.toppro}</p>
												</div>
												<div className="t-sale">
													<p>{data?.topsale}</p>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ================================================== */}

			<Footer />
		</>
	);
}

export default Shopdetail;
