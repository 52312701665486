import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";
import { arrow } from "../../constant/Index";
import Sidebar from "./Sidebar";

function Addnewaddress() {
  return (
    <>
      <Header />
      {/* Add Address section */}
      <section className="add-new-address my-profile-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
                <Sidebar />
            </div>
            <div className="col-md-9 profile-all">
              <div className="add-all-new-address">
                <div className="row">
                  <div className="col-md-12">
                    <div className="my-profile-head-and-btn">
                      <h3>My Addresses</h3>
                      <div className="banner-button login-btn profile-edit-btn">
                        <Link to="/my-address" className="all-button">
                        SAVE ADDRESS<img className="img-fluid" src={arrow} />{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="add-address-input">
                        <label>Address </label>
                        <input type="text" placeholder="Enter Address" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="add-address-input">
                        <label>City</label>
                        <input type="text" placeholder="Enter city" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="add-address-input">
                        <label>Country</label>
                        <input type="text" placeholder="Enter country" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="add-address-input">
                        <label>State</label>
                        <input type="text" placeholder="Enter state" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="add-address-input">
                        <label>Zipcode</label>
                        <input type="text" placeholder="Enter zipcode" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Threelinks />
      </section>

      <Footer />
    </>
  );
}

export default Addnewaddress;
