import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageHeader from "../../components/Pageheader";
import { arrow, blackflower, checkoutimg, dollar } from "../../constant/Index";

function Membercheckout() {
  return (
    <>
      <Header />
      <PageHeader pagename="Checkout" />

      <section className="check-out-sec member-checkout-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="all-checkout">
                <div className="billin-dv">
                  <h4>BILLING DETAILS</h4>
                  <p>
                    Already Have an account? <Link to="/login">Log in</Link>
                  </p>
                </div>
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-dv-style-select">
                        <label>Shipment Method</label>
                        <select
                          class="form-select form-select-lg mb-3"
                          aria-label=".form-select-lg example"
                        >
                          <option selected>Select Shipment method</option>
                          <option value="1">Select Shipment method</option>
                          <option value="2">Select Shipment method</option>
                          <option value="3">Select Shipment method</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-dv-input check-out-input">
                        <label>First Name</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-dv-input check-out-input">
                        <label>Last Name</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-dv-input check-out-input">
                        <label>Phone</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-dv-input check-out-input">
                        <label>Email</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-dv-input check-out-input">
                        <label>Address</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-dv-input check-out-input">
                        <label>Suite, Apartment, etc </label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-dv-input check-out-input">
                        <label>City</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-dv-input check-out-input">
                        <label>Country/Region</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-dv-input check-out-input">
                        <label>State</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-dv-input check-out-input">
                        <label>Zip Code</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="check-and-text">
                        <div class="form-group">
                          <input type="checkbox" id="check" />
                          <label for="check">Save Shipping Details</label>
                        </div>
                      </div>
                    </div>
{/* 
                    <div className="col-md-12">
                      <div className="form-dv-input check-out-input">
                        <label>Payment Details</label>
                        <input type="date" />
                      </div>
                    </div> */}
                    
                    <div className="col-md-12">
                      <div className="form-dv-input check-out-input">
                        <label>Payment Details</label>
                        <input type="text" placeholder="mm/yy       cvc"/>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="banner-button submit-banner-btn login-btn memeber-check">
                        <Link to="/profile" className="all-button">
                          add card{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-md-4">
              <div className="summary-dv">
                <h4>MEMBERSHIP DETAILS</h4>
              </div>

              <div className="all-become-member-div">
                <div className="first-dv">
                  <div className="member-text">
                    <h5>Basic</h5>
                    <h2>
                      <span className="dollar"><img className="img-fluid" src={dollar} /></span>350<span>/mo</span>
                    </h2>
                  </div>
                </div>
                <div className="become-member-ul">
                  <ul className="member-ul">
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Member Card
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Earn Bonus
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Lounge Access
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Free Smaple
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Special Offers
                    </li>
                  </ul>

                  <div className="banner-button member-btn login-btn">
                    <Link className="all-button">
                      BUY NOW <img className="img-fluid" src={arrow} />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Second Row */}
          <div className="row check-box-row">
            <div className="col-md-6">
              <div className="check-and">
                <div class="form-group">
                  <input type="checkbox" id="bottom-check" />
                  <label for="bottom-check">
                    Proident, sunt in culpa qui officia deserunt mollit anim id
                    est laborum.
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="banner-button checkout-btns login-btn">
                <Link to="/thank-you" className="all-button">
                  CONFIRM PAYMENT <img className="img-fluid" src={arrow} />{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Membercheckout;
