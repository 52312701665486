import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";
import { myorder } from "../../constant/Index";
import Sidebar from "./Sidebar";

function Myorders() {
  return (
    <>
      <Header />
      {/* Myorders Section */}

      <section className="my-orders-sec my-profile-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9 profile-all">
              <div className="row">
                <div className="col-md-12">
                  <div className="my-profile-head-and-btn my-order-heading">
                    <h3>My ORDERS</h3>
                  </div>
                </div>
              </div>
              <div className="table-all">
                <table>
                  <thead>
                    <th></th>
                    <th>OrderNo</th>
                    <th>Order Date</th>
                    <th>Status</th>
                    <th>Total</th>
                    <th></th>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="for-left-border">
                        <img className="img-fluid" src={myorder} />
                      </td>
                      <td>
                        <p>#1084598423154</p>
                      </td>
                      <td>
                        <p>04-12-21</p>
                      </td>
                      <td>
                        <p>Pending</p>
                      </td>
                      <td>
                        <p>$590.00</p>
                      </td>
                      <td className="for-right-border">
                        <Link to="/order-view-detail">view Details</Link>
                      </td>
                    </tr>
                    <tr>
                      <td className="for-left-border">
                        <img className="img-fluid" src={myorder} />
                      </td>
                      <td>
                        <p>#1084598423154</p>
                      </td>
                      <td>
                        <p>04-12-21</p>
                      </td>
                      <td>
                        <p>Pending</p>
                      </td>
                      <td>
                        <p>$590.00</p>
                      </td>
                      <td className="for-right-border">
                        <Link to="/order-view-detail">view Details</Link>
                      </td>
                    </tr>
                    <tr>
                      <td className="for-left-border">
                        <img className="img-fluid" src={myorder} />
                      </td>
                      <td>
                        <p>#1084598423154</p>
                      </td>
                      <td>
                        <p>04-12-21</p>
                      </td>
                      <td>
                        <p>Pending</p>
                      </td>
                      <td>
                        <p>$590.00</p>
                      </td>
                      <td className="for-right-border">
                        <Link to="/order-view-detail">view Details</Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Threelinks />
      </section>
      <Footer />
    </>
  );
}

export default Myorders;
