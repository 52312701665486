import React, { useState } from "react";
import Slider from "react-slick";
import {
  arrow2,
  blackflower,
  shop1,
  shop2,
  shop3,
  shop4,
  shop5,
  shop6,
  pro1,
} from "../constant/Index";

export default function App() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  return (
    <div>
      <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
        <div className="all-borders">
          <img className="img-fluid" src={pro1} />{" "}
        </div>
        <div className="all-borders">
          <img className="img-fluid" src={pro1} />
        </div>
        <div className="all-borders">
          <img className="img-fluid" src={pro1} />
        </div>
        <div className="all-borders">
          <img className="img-fluid" src={pro1} />
        </div>
        <div className="all-borders">
          <img className="img-fluid" src={pro1} />
        </div>
        <div className="all-borders">
          <img className="img-fluid" src={pro1} />
        </div>
      </Slider>

      <Slider
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        slidesToShow={4}
        swipeToSlide={true}
        focusOnSelect={true}
      >
        <div className="">
          <img className="img-fluid on-small-border"  src={pro1} />
        </div>
        <div >
          <img className="img-fluid on-small-border" src={pro1} />
        </div>
        <div > 
          <img className="img-fluid on-small-border" src={pro1} />
        </div>
        <div>
          <img className="img-fluid on-small-border" src={pro1} />
        </div>
        <div > 
          <img className="img-fluid on-small-border" src={pro1} />
        </div>
        <div >
          <img className="img-fluid on-small-border" src={pro1} />
        </div>
      </Slider>
    </div>
  );
}
