import React from 'react'

function Threelinks() {
  return (
    <>
    <div className="three-links">
        <a href="">Facebook</a> 
        <a href="">Instagram</a> 
        <a href="">Twitter</a> 

    </div>
    </>
  )
}

export default Threelinks