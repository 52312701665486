import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import bannervideo1 from "../../assets/img/bannervideo1.mp4";
import Header from "../../components/Header";

import {
  arrow,
  homeabout,
  abouticon,
  hand,
  blackflower,
  shop1,
  arrow2,
  gallery1,
  gallery2,
  gallery3,
  smallarrow,
  grass,
  banner1,
  banner2,
  banner3,
  homevideo,
  shop2,
  shop3,
  shop4,
  shop5,
  shop6,
  dollar,
  sidemove,
  websitelogo,
  sidemovetwo,
} from "../../constant/Index";
import Threelinks from "../../components/Threelinks";

function Home() {
  const shop = [
    {
      id: 1,
      img: shop1,
      heading: "FORUM COOKIE",
      para: "Lorem ipsum dolor sit amet psum dol",
      price: "USD 19.39",
      span: "THC : (25.8%)",
      g1: "7G",
      g2: "24G",
    },
    {
      id: 2,
      img: shop2,
      heading: "FORUM COOKIE",
      para: "Lorem ipsum dolor sit amet psum dol",
      price: "USD 19.39",
      span: "THC : (25.8%)",
      g1: "7G",
      g2: "24G",
    },
    {
      id: 3,
      img: shop3,
      heading: "FORUM COOKIE",
      para: "Lorem ipsum dolor sit amet psum dol",
      price: "USD 19.39",
      span: "THC : (25.8%)",
      g1: "7G",
      g2: "24G",
    },
    {
      id: 4,
      img: shop4,
      heading: "FORUM COOKIE",
      para: "Lorem ipsum dolor sit amet psum dol",
      price: "USD 19.39",
      span: "THC : (25.8%)",
      g1: "7G",
      g2: "24G",
    },
    {
      id: 5,
      img: shop5,
      heading: "FORUM COOKIE",
      para: "Lorem ipsum dolor sit amet psum dol",
      price: "USD 19.39",
      span: "THC : (25.8%)",
      g1: "7G",
      g2: "24G",
    },
    {
      id: 6,
      img: shop6,
      heading: "FORUM COOKIE",
      para: "Lorem ipsum dolor sit amet psum dol",
      price: "USD 19.39",
      span: "THC : (25.8%)",
      g1: "7G",
      g2: "24G",
    },
  ];

  return (
    <>
      <Header />

      {/* Banner-section */}
      <section className="banner-sec">
        <div className="background-video">
          <video loop muted autoPlay={"autoplay"}>
            <source src={bannervideo1} />
          </video>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="banner-text">
                <h5>
                  SMOKE <span className="for-gap-in-words">PIPE</span> EVERYDAY
                </h5>
                <h2>
                  Pipe Paradise Smoke Shop<span>.</span>
                </h2>
                <h4>Open 7 Days A Week • Walk-Ins Welcome • Private Lounge</h4>
                {/* <p>
                  Stop by and score the best stuff in the market. orderzaza
                  has brought a large collection of quality cannabis. Browse
                  through our collection to{" "}
                  <span>
                    make us of our products today with our limited free
                    membership!
                  </span>
                </p> */}
                {/* <p>
                  Stop by and score the best stuff in the market. orderzaza has
                  brought a large collection of quality cannabis. Browse through
                  our collection to learn more about our products.
                </p> */}
                <p>
                  Stop by and score the best stuff in the market. Pipe paradise
                  has brought a large collection of quality cannabis. Browse
                  through our collection to learn more about our products.{" "}
                </p>
                <div className="banner-button only-for-banner-btn">
                  <Link to="/shop" className="all-button">
                    SHOP NOW <img className="img-fluid" src={arrow} />{" "}
                  </Link>
                </div>
              </div>

              <div className="video-on-home">
                <div className="video-img">
                  <img className="img-fluid" src={homevideo} />
                  <div className="video-icon-dv">
                    <div className="video-icon">
                      <div className="play-border">
                        <i class="fa fa-play" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="video-text">
                  <h5>Open 7 days a week</h5>
                  <p>
                    We know the urgency and need for clean green, so we’re
                    available every day for our customers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 for-hide"></div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="all-forum-img for-slightly-moving">
                    <div className="forum-img">
                      <img className="img-fluid" src={banner1} />

                      <div className="forum-text">
                        <h3>Forum Cookie</h3>
                        <h4>USD 19.39</h4>
                      </div>
                      <div className="rating-start">
                        <h4>
                          4.8{" "}
                          <span>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="all-forum-img">
                    <div className="forum-img">
                      <img className="img-fluid" src={banner2} />

                      <div className="forum-text">
                        <h3>Forum Cookie</h3>
                        <h4>USD 19.39</h4>
                      </div>
                      <div className="rating-start">
                        <h4>
                          4.8{" "}
                          <span>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="all-forum-img for-slightly-moving">
                    <div className="forum-img">
                      <img className="img-fluid" src={banner3} />

                      <div className="forum-text">
                        <h3>Forum Cookie</h3>
                        <h4>USD 19.39</h4>
                      </div>
                      <div className="rating-start">
                        <h4>
                          4.8{" "}
                          <span>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Threelinks />
        <div className="side-move-pic">
          <img className="img-fluid marquee" src={sidemovetwo} />
        </div>
      </section>
      {/* ============================================== */}

      {/* About section */}
      <section className="banner-about-sec about-main-home-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about-img">
                <div className="about-icon-img">
                  <div className="img-1">
                    <img className="img-fluid" src={hand} />
                    <h5>SMOKE LOUNGE</h5>
                  </div>
                </div>
                <img className="img-fluid" src={homeabout} />
                <div className="about-icon-img2">
                  <div className="img-2">
                    <img className="img-fluid" src={abouticon} />
                    <h5>CANABIS SMOKING</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="about-banner-text banner-text home-about-banner-responsive">
                <h5>
                  PRIVATE <span className="for-gap-in-words">MEMBERS </span>{" "}
                  LOUNGE
                </h5>
                {/* <h2>
                  ABOUT orderzaza<span>.</span>
                </h2> */}
                <h2>
                  ABOUT PIPE PARADISE <span>.</span>
                </h2>

                {/* <p>
                  orderzaza spurred from a thought in 2019 in Michigan, Detroit,
                  and what started small turned into two functional locations by
                  2020. Our friendly 420 smoke lounge is comfortable and packed
                  with vibes –TVs, large speakers, refreshments, smoke vents,
                  and comfortable seating. You will get everything you need.
                </p> */}
                <p>
                  Pipe Paradise spurred from a thought in 2019 in Michigan,
                  Detroit, and what started small turned into two functional
                  locations by 2020. Our friendly 420 smoke lounge is
                  comfortable and packed with vibes –TVs, large speakers,
                  refreshments, smoke vents, and comfortable seating. You will
                  get everything you need.{" "}
                </p>
                <p>
                  We are open 7 days a week to provide you with the perfect spot
                  for the best smoke experience. Forget all that pent-up stress
                  and sink in our locations. What’s more, is that we conduct
                  FREE events in the lounge area occasionally or have social
                  gatherings. We’re BYOB permitted and completely legitimate. To
                  attend our lounge, we have the obvious 21+ guidelines for
                  everyone.
                </p>

                {/* <p>
                  orderzaza is all about you and providing you with a
                  tension-free atmosphere you won’t find elsewhere. Check us out
                  for all the comfort and vibes your smoke lacks.{" "}
          
                </p> */}
                <p>
                  Pipe paradise is all about you and providing you with a
                  tension-free atmosphere, you won’t find elsewhere. Check us
                  out for all the comfort and vibes your smoke lacks.{" "}
                </p>
                <div className="banner-button home-about-banner-btn">
                  <Link to="/about-us" className="all-button">
                    L E A R N <span className="for-gap-in-words">M O R E</span>
                    <img className="img-fluid" src={arrow} />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ================================================================= */}

      {/* Smoke shop section */}
      <section className="smoke-shop-section">
        <div className="grass-img">
          <img className="img-fluid" src={grass} />
        </div>
        <div className="container">
          <div className="about-banner-text shop-banner-text">
            <h5>
              Smoke <span className="for-gap-in-words">Pipe</span> Everyday
            </h5>
            {/* <h2>
              Shop orderzaza<span>.</span>
            </h2> */}
            <h2>
              Shop Pipe Paradise <span>.</span>
            </h2>

            {/* <p>
              The Orderzaza has gathered all your demands in one large
              collection of our top-quality products. Browse and take your pick
              to level up.
            </p> */}
            <p>
              The Pipe Paradis has gathered all your demands in one large
              collection of our top-quality products. Browse and take your pick
              to level up.
            </p>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="shop-category">
                <ul className="shop-category-ul">
                  <div className="shop-cate-div">
                    <h3>CATEGORIES</h3>
                  </div>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Sale Items
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Mushrooms
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      100 Ounces
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      What's New
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Preroll Blunt
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Vape Pen
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Preroll Blunt
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Indica Flowers
                    </Link>
                  </li>
                  <li className="for-link-flex">
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Hybrids Flowers{" "}
                    </Link>
                    <p>SAVE %25</p>
                  </li>
                  <li>
                    <Link>
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Concentrates
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-9">
              <div className="row">
                {shop.map((data, index) => {
                  return (
                    <div className="col-md-4">
                      <div className="all-shop-product-div">
                        <div className="shop-img">
                          <img className="img-fluid" src={data?.img}></img>
                        </div>
                        <h2>{data?.heading}</h2>
                        <p>{data?.para}</p>
                        <h5>
                          {data?.price}
                          <span className="small-data">{data?.span}</span>
                        </h5>
                        <div className="sevev-display">
                          <h4 className="given-margin">{data?.g1}</h4>
                          <h4>{data?.g2}</h4>
                        </div>

                        <div className="Link-all">
                          <Link to="/shop-detail">
                            ADD TO CART{" "}
                            <img className="img-fluid" src={arrow2} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ================================================== */}

      {/* Become Member section  */}
      <section className="become-member-sec">
        <div className="container">
          <div className="about-banner-text become-member-head">
            <h5>Become Member</h5>
            <h2>
              become a member<span>.</span>
            </h2>
            <p>
              Experience to the maximum with our membership. Without further
              ado, be a part of our exclusive club.
            </p>
            <p>
              <span> Use the free membership while its available!</span>
            </p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="all-become-member-div">
                <div className="first-dv">
                  <div className="member-text">
                    <h5>Basic</h5>
                    <h2>
                      <span className="dollar">
                        <img className="img-fluid" src={dollar} />
                      </span>
                      350<span> /mo</span>
                    </h2>
                  </div>
                </div>
                <div className="become-member-ul">
                  <ul className="member-ul">
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Member Card
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Earn Bonus
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Lounge Access
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Free Sample
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Special Offers
                    </li>
                  </ul>

                  <div className="banner-button member-btn">
                    <Link to="/member-checkout" className="all-button">
                      BUY NOW <img className="img-fluid" src={arrow} />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="all-become-member-div">
                <div className="first-dv">
                  <div className="member-text">
                    <h5>Basic</h5>
                    <h2>
                      <span className="dollar">
                        <img className="img-fluid" src={dollar} />
                      </span>
                      350<span> /mo</span>
                    </h2>
                  </div>
                </div>
                <div className="become-member-ul">
                  <ul className="member-ul">
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Member Card
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Earn Bonus
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Lounge Access
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Free Sample
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Special Offers
                    </li>
                  </ul>

                  <div className="banner-button member-btn">
                    <Link to="/member-checkout" className="all-button">
                      BUY NOW <img className="img-fluid" src={arrow} />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="all-become-member-div">
                <div className="first-dv">
                  <div className="member-text">
                    <h5>Basic</h5>
                    <h2>
                      <span className="dollar">
                        <img className="img-fluid" src={dollar} />
                      </span>
                      350<span> /mo</span>
                    </h2>
                  </div>
                </div>
                <div className="become-member-ul">
                  <ul className="member-ul">
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Member Card
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Earn Bonus
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Lounge Access
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Free Sample
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Special Offers
                    </li>
                  </ul>

                  <div className="banner-button member-btn">
                    <Link to="/member-checkout" className="all-button">
                      BUY NOW <img className="img-fluid" src={arrow} />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========================================================== */}

      {/* Spining section */}
      <section className="spin-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="spin-text">
                <div className="about-banner-text shop-banner-text spin-div">
                  <h5>
                    SMOKE <span className="for-gap-in-words">PIPE</span>{" "}
                    EVERYDAY
                  </h5>
                  <h2>Questions spinning in your mind?</h2>
                  <p>
                    We won’t make you wait for the answers, so drop us your
                    thoughts, and we’ll get back to you.
                  </p>
                  <div className="banner-button spin-btn">
                    <Link to="/contact-us" className="all-button">
                      CONTACT US
                      <img className="img-fluid" src={arrow} />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========================================================== */}

      {/* Orderzaza Section */}
      <section className="order-zaza-sec">
        <div className="container">
          <div className="order-zaza-head">
            <h2>Order ZaZa</h2>
            <p>we use the delivery service Order ZaZa</p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="order-zaza-all-stuff">
                <img alt="order-zaza-img" src={websitelogo} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Gallery Section  */}
      <section className="gallery-sec">
        <div className="container">
          <div className="about-banner-text shop-banner-text galler-head">
            <h5>
              SMOKE <span className="for-gap-in-words">PIPE</span> EVERYDAY
            </h5>
            <h2>
              OUR GALLERY<span>.</span>
            </h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="gallery-all">
                <div className="gallery-img">
                  <img className="img-fluid" src={gallery1} />
                </div>
                <div className="small-arrow-img">
                  <img className="img-fluid" src={smallarrow} />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="gallery-all">
                <div className="gallery-img">
                  <img className="img-fluid" src={gallery2} />
                </div>
                <div className="small-arrow-img">
                  <img className="img-fluid" src={smallarrow} />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="gallery-all">
                <div className="gallery-img">
                  <img className="img-fluid" src={gallery3} />
                </div>
                <div className="small-arrow-img">
                  <img className="img-fluid" src={smallarrow} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==================================================== */}

      {/* Newsletter section  */}
      <section className="newsletter-sec">
        <div className="container">
          <div className="about-banner-text become-member-head subscribe-head">
            <h5>
              SMOKE <span className="for-gap-in-words">PIPE</span> EVERYDAY
            </h5>
            <h2>
              SUBSCRIBE TO THE NEWSLETTER<span>.</span>
            </h2>
            <p>Exclusive discounts and special occasions right in your inbox</p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="subscribe">
                <div className="newsletter-input">
                  <input type="text" placeholder="Enter your email address" />
                </div>
                <div className="banner-button subscribe-btn">
                  <Link className="all-button">
                    SUBSCRIBE
                    <img className="img-fluid" src={arrow} />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======================================================= */}

      <Footer />
    </>
  );
}

export default Home;
