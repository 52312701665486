import React, { useState } from "react";
import { useLocation, NavLink, Link } from "react-router-dom";
import { logo, bag, profileimg, plant } from "../constant/Index";

function Header() {
	//assigning location variable
	const location = useLocation();
	const [view, setView] = useState(false);

	//destructuring pathname from location
	const { pathname } = location;

	//Javascript split method to get the name of the path in array
	const splitLocation = pathname.split("/");
	return (
		<>
			<section className="header-sec">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-2">
							<div className="web-logo">
								<Link to="/">
									<img className="img-fluid" src={logo}></img>
								</Link>
							</div>
						</div>
						<div className="col-md-6">
							<div className="nav-home">
								<ul className="home-ul">
									<li className={splitLocation[1] === "" ? "active" : ""}>
										{" "}
										<NavLink to="/">home</NavLink>
									</li>
									<li className={splitLocation[1] === "about" ? "active" : ""}>
										{" "}
										<NavLink to="/events">events & specials</NavLink>
									</li>
									<li>
										{" "}
										<NavLink to="/shop">shop</NavLink>
									</li>
									<li>
										{" "}
										<NavLink to="/lounge-rental">lounge rental</NavLink>
									</li>
									<li>
										{" "}
										<NavLink to="/membership">membership</NavLink>
									</li>
									<li>
										{" "}
										<NavLink to="/locations">locations</NavLink>
									</li>
									<li>
										{" "}
										<NavLink to="/faq">faq</NavLink>
									</li>
									<li>
										{" "}
										<NavLink to="/contact-us">contact us</NavLink>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-4">
							<div className="all-for-flex">
								<div className="search">
									<input type="text" placeholder="SEARCH" />
									<i class="fa fa-search" aria-hidden="true"></i>
									<Link to="/cart">
										{" "}
										<img className="img-fluid" src={bag} />
									</Link>
									<div className="shopping-bag-icon">
										<p>0</p>
									</div>
								</div>

								<div className="profile-on-header">
									<Link to="/login">
										<div className="profile-text">
											<h4>WELCOME BACK</h4>
											<h5>JOHN DOE</h5>
										</div>
									</Link>
									<div className="profile-img">
										<img className="img-fluid" src={profileimg} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="canvas_btn " onClick={() => setView(true)}>
				<Link to="/"><img className="img-fluid" src={logo}></img></Link>
				<i className="fa fa-bars" aria-hidden="true"></i>
			</div>
			{/* ========================================= */}

			{/* <!-- Mobile Header Start Here --> */}
			<div className={view ? "mobile_header show" : "mobile_header "}>
				<div className="cancel" onClick={() => setView(false)}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="25"
						height="25"
						fill="#000"
						className="bi bi-x"
						viewBox="0 0 16 16"
					>
						<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
					</svg>
				</div>

				<ul className="mobile_menus">
					<li className="menu-items">
						<Link to="/"> Home</Link>
					</li>
					<li className="menu-items">
						<Link to="/events"> Events & Specials</Link>
					</li>
					<li className="menu-items">
						<Link to="/shop"> shop</Link>
					</li>
					<li className="menu-items">
						<Link to="/lounge-rental"> lounge rental</Link>
					</li>
					<li className="menu-items">
						<Link to="/membership"> memberships</Link>
					</li>
					<li className="menu-items">
						<Link to="/locations"> locations</Link>
					</li>
					<li className="menu-items">
						<Link to="/faq"> faq</Link>
					</li>
					<li className="menu-items">
						<Link to="/contact-us"> contact us</Link>
					</li>

					<li className="for-responsive-login-btn">
						{" "}
						<div className="profile-on-header">
							<Link to="/login">
								<div className="profile-text">
									<h4>WELCOME BACK</h4>
									<h5>JOHN DOE</h5>
								</div>
							</Link>
							<div className="profile-img">
								<img className="img-fluid" src={profileimg} />
							</div>
						</div>
					</li>
					<div className="plant-img responsive-plant-img">
						<img className="img-fluid" src={plant} />
					</div>
				</ul>
			</div>
			{/* =================================== */}
		</>
	);
}

export default Header;
