import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import faq from "../../assets/css/faq.css";
import PageHeader from "../../components/Pageheader";
import Threelinks from "../../components/Threelinks";

function Faq() {
  const FaqsData = [
    {
      id:1,
      question: "What is Pipe Paradise? ",
      answer: "Pipe Paradise is a premier retailer of high-quality cannabis products. We offer a wide range of options to suit all types of consumers, from the occasional user to the seasoned connoisseur. In addition to our retail offerings, we also have a 420 smoke lounge where customers can relax and enjoy their purchases."
    },
    {
      id:2,
      question: "What products do you carry?",
      answer: "We offer a wide variety of cannabis products, including flowers, edibles, concentrates, tinctures, and topicals. We also carry a selection of smoking accessories and other related products."
    },
    {
      id:3,
      question: "Are your locations only for cannabis use?",
      answer: "Our 420 smoke lounge areas are designated for the consumption of cannabis products. We do not permit the use of any other substances in these areas.",
    },
    {
      id:4,
      question: "Do you have any age restrictions?",
      answer: "Yes, all of our locations are only open to those 21 and over. We adhere strictly to all laws and regulations regarding the sale of cannabis and require valid identification from all customers."
    },
    {
      id:5,
      question: "Which delivery services do you use?",
      answer: "We've teamed up with the amazing folks at Order ZaZa to give our customers the ultimate delivery experience. We're all about providing top-notch service, and Order ZaZa fits the bill perfectly with its reliability and convenience."
    }
  ]
  return (
    <>
      <Header />

      <PageHeader
        pagename="FREQUENTLY ASKED QUESTIONS"
        pagepara="Below are mentioned some of the common pressing questions about our company. Feel free to contact us if you have a query not addressed here."
      />
      {/* Faq Section */}
      <section className="faq-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="faq-all">
                <div class="accordion" id="accordionExample">
                {FaqsData.map((data, index) => {
                  return (
                  <div class="accordion-item" key={index}>
                    <h2 class="accordion-header" id={`#heading${data?.id}`}>
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${data?.id}`}
                        aria-expanded="true"
                        aria-controls={`collapse${data?.id}`}
                      >
                        <span>
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </span>
                        <h5>{data?.question}</h5>
                      </button>
                    </h2>
                    <div
                      id={`collapse${data?.id}`}
                      class={`${(index == 0) ? "accordion-collapse collapse show" : "accordion-collapse collapse"}`}
                      aria-labelledby={`#heading${data?.id}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="accord-para">
                          <p>
                          {data?.answer}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  );
                })}
                  {/* <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <span>
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </span>
                        <h5> How Much orderzaza lounge charge?</h5>
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="accord-para">
                          <p>
                            Sed velit dignissim sodales ut eu sem. Phasellus
                            egestas tellus rutrum tellus pe lle ntesque eu
                            tincidunt tortor aliquam. Elit eget gravida cum
                            sociis. Vitae turpis massa sed elementum tempus
                            egestas. Eu tincidunt tortor aliquam nulla facilisi
                            cras fermentum odio eu. Sed risus pretium
                            Condimentum lacinia quis vel eros donec ac. Sem et
                            tortor consequa
                          </p>
                          <p>
                            Sed velit dignissim sodales ut eu sem. Phasellus
                            egestas tellus rutrum tellus pe lle ntesque eu
                            tincidunt tortor aliquam. Elit eget gravida cum
                            sociis. Vitae turpis massa sed elementum tempus
                            egestas. Eu tincidunt tortor aliquam nulla facilisi
                            cras fermentum odio eu. Sed risus pretium
                            Condimentum lacinia quis ve
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <span>
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </span>
                        <h5> How Much orderzaza lounge charge?</h5>
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="accord-para">
                          <p>
                            Sed velit dignissim sodales ut eu sem. Phasellus
                            egestas tellus rutrum tellus pe lle ntesque eu
                            tincidunt tortor aliquam. Elit eget gravida cum
                            sociis. Vitae turpis massa sed elementum tempus
                            egestas. Eu tincidunt tortor aliquam nulla facilisi
                            cras fermentum odio eu. Sed risus pretium
                            Condimentum lacinia quis vel eros donec ac. Sem et
                            tortor consequa
                          </p>
                          <p>
                            Sed velit dignissim sodales ut eu sem. Phasellus
                            egestas tellus rutrum tellus pe lle ntesque eu
                            tincidunt tortor aliquam. Elit eget gravida cum
                            sociis. Vitae turpis massa sed elementum tempus
                            egestas. Eu tincidunt tortor aliquam nulla facilisi
                            cras fermentum odio eu. Sed risus pretium
                            Condimentum lacinia quis ve
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingfourth">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsefourth"
                        aria-expanded="false"
                        aria-controls="collapsefourth"
                      >
                        <span>
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </span>
                        <h5> How Much orderzaza lounge charge?</h5>
                      </button>
                    </h2>
                    <div
                      id="collapsefourth"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingfourth"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="accord-para">
                          <p>
                            Sed velit dignissim sodales ut eu sem. Phasellus
                            egestas tellus rutrum tellus pe lle ntesque eu
                            tincidunt tortor aliquam. Elit eget gravida cum
                            sociis. Vitae turpis massa sed elementum tempus
                            egestas. Eu tincidunt tortor aliquam nulla facilisi
                            cras fermentum odio eu. Sed risus pretium
                            Condimentum lacinia quis vel eros donec ac. Sem et
                            tortor consequa
                          </p>
                          <p>
                            Sed velit dignissim sodales ut eu sem. Phasellus
                            egestas tellus rutrum tellus pe lle ntesque eu
                            tincidunt tortor aliquam. Elit eget gravida cum
                            sociis. Vitae turpis massa sed elementum tempus
                            egestas. Eu tincidunt tortor aliquam nulla facilisi
                            cras fermentum odio eu. Sed risus pretium
                            Condimentum lacinia quis ve
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Threelinks />
      </section>

      <Footer />
    </>
  );
}

export default Faq;
