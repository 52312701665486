import React from "react";
import { Link } from "react-router-dom";
import { footerlogo } from "../constant/Index";

function Footer() {
  return (
    <>
      {/* Footer section */}
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-web-logo">
                <img className="img-fluid" src={footerlogo} />
              </div>
            </div>

            <div className="col-md-2">
              <div className="footer-anchor">
                <a
                  href="mailto:info@pipeparadise.net
"
                >
                  info@pipeparadise.net
                </a>{" "}
                <br />
                <a href="tel:+455 45 454555"> +455 45 454555</a>
              </div>
            </div>

            <div className="col-md-2">
              <div className="footer-address">
                <p>004 Riley Street, Surry</p>
                <p> Hills 2050 Chicago, Usa</p>
              </div>
            </div>

            <div className="col-md-1">
              <div className="footer-links">
                <a href="">Facebook</a>
                <a href="">Twitter</a>
              </div>
            </div>
            <div className="col-md-1">
              <div className="footer-links">
                <a href="">Vimeo</a>
                <a href="">Instagram</a>
              </div>
            </div>
            <div className="col-md-1">
              <div className="footer-links">
                <a href="">Google+</a>
                <a href="">Youtube</a>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="copy-right">
                <p>
                  © Copyright <span>PIPEPARADISE </span> 2022. All Right
                  Reserved.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="terms-about-policy">
                <Link to="/about-us">About Us</Link>
                <Link to="/terms-condition" className="brder-left-right">Terms and Conditions</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* =========== */}
    </>
  );
}

export default Footer;
