import React from "react";
import { Link } from "react-router-dom";
import thankyou from "../../assets/css/thankyou.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";

function Thankyou() {
	return (
		<>
			<Header />

			{/* Thank You Section */}

			<section className="thank-you-sec">
				<div className="container">
					<div className="thank-you-head">
						<h3>Thank You for Shopping!!</h3>
						<h4>Your Order is confirmed</h4>
						<h5>OrderNo # 1084598423154</h5>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="thank-you-all">
								<div className="inner-all">
									<div className="thank-for-shopping-dv first-div">
										<div className="shippment-heading">
											<h3>SHIPMENT ADDRESS</h3>
										</div>
										<div className="inner-stuff">
											<p className="for-light">Country:</p>
											<p className="for-dark">United States</p>
										</div>

										<div className="inner-stuff">
											<p className="for-light">Address:</p>
											<p className="for-dark">
												10515 Fox Ave Fairdale, Kentucky(KY), 40118
											</p>
										</div>

										<div className="inner-stuff">
											<p className="for-light">Phone:</p>
											<p className="for-dark">
												<a href="tel+44-123-456-789">+44-123-456-789</a>
											</p>
										</div>
										<div className="inner-stuff">
											<p className="for-light">State:</p>
											<p className="for-dark">Texas</p>
										</div>
									</div>

									<div className="thank-for-shopping-dv second-div">
										<div className="shippment-heading">
											<h3>PAYMENT DETAILS</h3>
										</div>
										<div className="inner-stuff-price">
											<p className="for-light">Total :</p>
											<p className="for-dark">$152.00</p>
										</div>

										<div className="inner-stuff-price">
											<p className="for-light">Shipping Charges : </p>
											<p className="for-dark">$152.00</p>
										</div>

										<div className="inner-stuff-price">
											<p className="for-light">Taxes Applied : </p>
											<p className="for-dark">$52.00</p>
										</div>

										<div className="inner-stuff-price for-extra-padding">
											<p className="for-light">Order Total :</p>
											<p className="for-dark">$3025.00</p>
										</div>
									</div>
								</div>
							</div>
							<div className="banner-button submit-banner-btn login-btn thanku-btn">
								<Link to="/shop" className="all-button">
									CONTINUE SHOPPING
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ========================================= */}
			<Threelinks />
			<Footer />
		</>
	);
}

export default Thankyou;
