import React from "react";
import Header from "../../components/Header";
import { arrow, contact } from "../../constant/Index";
import cont from "../../assets/css/contact.css";
import { Link } from "react-router-dom";
import Threelinks from "../../components/Threelinks";
import Footer from "../../components/Footer";

function Contact() {
  return (
    <>
      <Header />

      {/* Contact Section */}
      <section className="contact-sec">
        <div className="container">
          <div className="row for-border-and-color">
            <div className="col-md-4">
              <div className="contact-img">
                <img className="img-fluid" src={contact} />
                <div className="call-now-green-box">
                  <div className="call call-now-anchor">
                    <h3>Call Now</h3>
                    <a href="tel:+84 (400) 333 5526">+84 (400) 333 5526</a>
                    <a className="anchor-edit" href="mailto:pipeparadise@example.com">
                      pipeparadise@example.com
                    </a>
                  </div>

                  <div className="call opening-hour for-border">
                   <h3> OPENING HOURS</h3>
                    <p>Mon - Fri: 5:00 pm – 10:00 pm</p>
                    <p>Mon - Fri: 5:00 pm – 10:00 pm</p>
                  </div>

                  <div className="call opening-hour locat">
                      <h3>LOCATION</h3>
                    <p>23 denington Street</p>
                    <p>San FRANCISCO, CA 9465</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="contact-form-all">
                <div className="about-banner-text contact-upper-head">
                  {/* <h5>S M O K E <span className="for-gap-in-words">P I P E</span> E V E R Y D A Y</h5> */}
                  <h2>
                    CONTACT US FORM<span>.</span>
                  </h2>
                  <p>
                    Your email address will not be published.required fields are
                    marked <span>*</span>
                  </p>
                </div>
                <div className="form-for-contact">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="contact-form-input">
                          <input type="text" placeholder="First Name" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="contact-form-input">
                          <input type="text" placeholder="Last Name" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="contact-form-input">
                          <input type="text" placeholder="Email Address" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="contact-form-input">
                          <input type="text" placeholder="Phone Number" />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="contact-form-textarea">
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="Type your message here.."
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <div className="banner-button submit-banner-btn">
                          <Link to="/shop" className="all-button">
                            SUBMIT{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Threelinks />
      </section>
      {/* ========================================= */}
      <Footer />
    </>
  );
}

export default Contact;
